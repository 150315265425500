.App {
  background-color: #282c34;
  color: white;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.status-message {
  font-size: 32px;
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.video-list {
  background-color: #282c34;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: space-around;
  justify-content: space-around;
  padding: 5px;
}

.video-list-item {
  margin: 5px;
  width: 640px;
  max-width: 100%;
  height: 360px;
  max-height: calc(100vw * .5625)
}

.video-detail {
  text-align: center;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; 
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.react-player video {
  position: relative;
  left: 0;
  top: 0;
  transform: none;
}
